// Sentry
import * as Sentry from "@sentry/browser";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://5960beb57b394af7ac4686734ca574d7@o48945.ingest.sentry.io/5374557",
    environment: "prod",
  });
}

import "./alpine.js";
import "./billing.js";
import "./gallery.js";
import "./map.js";
import "./plausible.js";
import "./phone.js";
import "./upload.js";
import "phoenix_html";
