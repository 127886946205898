import "./jquery.js";
import Dropzone from "dropzone";

$(".browse").click(function () {
  $(this).parent().find("input:file").click();
});

$("input:file").on("change", function (e: any) {
  const file = e.target.files[0],
    reader = new FileReader();

  $("input:text", $(e.target).parent()).val(file.name);

  reader.addEventListener(
    "load",
    function () {
      $(".image-file-input").attr("src", String(reader.result));
    },
    false,
  );

  reader.readAsDataURL(file);
});

if (document.querySelector(".dropzone")) {
  new Dropzone(".dropzone").on("addedfile", (file) => {
    console.log(`File added: ${file.name}`);
  });
}

if (document.querySelector("#deck-plans")) {
  new Dropzone("#deck-plans").on("addedfile", (file) => {
    console.log(`File added: ${file.name}`);
  });
}
