import intlTelInput from "intl-tel-input";

const companyInput = document.querySelector(
  "#company-phone",
) as HTMLInputElement;

if (companyInput) {
  intlTelInput(companyInput, {
    initialCountry: "us",
  });
}

const profileInput = document.querySelector(
  "#profile-phone",
) as HTMLInputElement;

if (profileInput) {
  intlTelInput(profileInput, {
    initialCountry: "us",
  });
}
