declare const Stripe: any;

if (document.querySelector("#billing")) {
  const element: HTMLElement | null = document.querySelector("#billing");

  const stripe = Stripe(element?.dataset.stripe);

  stripe
    .redirectToCheckout({
      sessionId: element?.dataset.session,
    })
    .then(function (result: { error: { message: string } }) {
      console.log(result.error.message);
    });
}

export {};
