import "./jquery.js";
import "blueimp-gallery";
import Alpine from "alpinejs";

document.addEventListener("alpine:init", () => {
  const focusTrap = (element: HTMLElement | null) => {
    const getFocusableElements = (element: HTMLElement | typeof document) => {
      return [
        ...element.querySelectorAll(
          'a, button, details, input, select, textarea, [tabindex]:not([tabindex="-1"])',
        ),
      ].filter((e) => !e.hasAttribute("disabled"));
    };

    const focusableElements = getFocusableElements(element || document);
    const firstFocusableEl = focusableElements[0];
    const lastFocusableEl = focusableElements[focusableElements.length - 1];

    setTimeout(() => (firstFocusableEl as HTMLElement).focus(), 50);

    const handleKeyDown = (e: KeyboardEvent) => {
      const TAB = 9;
      const isTab = e.key.toLowerCase() === "tab" || e.keyCode === TAB;

      if (!isTab) return;

      if (e.shiftKey) {
        if (document.activeElement === firstFocusableEl) {
          (lastFocusableEl as HTMLElement).focus();
          e.preventDefault();
        }
      } else {
        if (document.activeElement === lastFocusableEl) {
          (firstFocusableEl as HTMLElement).focus();
          e.preventDefault();
        }
      }
    };

    element?.addEventListener("keydown", handleKeyDown);

    return function cleanup() {
      element?.removeEventListener("keydown", handleKeyDown);
    };
  };

  Alpine.data("data", () => ({
    isSideMenuOpen: false,
    toggleSideMenu() {
      this.isSideMenuOpen = !this.isSideMenuOpen;
    },
    closeSideMenu() {
      this.isSideMenuOpen = false;
    },
    isProfileMenuOpen: false,
    toggleProfileMenu() {
      this.isProfileMenuOpen = !this.isProfileMenuOpen;
    },
    closeProfileMenu() {
      this.isProfileMenuOpen = false;
    },
    isModalOpen: false,
    trapCleanup: null,
    modal() {
      return {
        isModalOpen: false,
        trapCleanup: null,
      };
    },
    openModal(element: string) {
      this.isModalOpen = true;
      (this.trapCleanup as any) = focusTrap(document.getElementById(element));
    },
    closeModal() {
      this.isModalOpen = false;
      this.trapCleanup = null;
    },
  }));
});

Alpine.start();
