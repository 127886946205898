import "./jquery.js";
import "blueimp-gallery";

declare const blueimp: any;

if (document.querySelector("#blueimp-gallery")) {
  blueimp.Gallery($("#links a"), {
    container: "#blueimp-gallery",
    carousel: true,
    continuous: true,
    enableKeyboardNavigation: true,
    startSlideshow: false,
    slideContentClass: "centered",
    onopened: function () {
      const slides = Array.from($(".slides").children());
      slides.forEach((slide) => {
        const child = slide.firstChild;
        if (child !== null) {
          const clone: any = child.cloneNode(true);
          clone.className = "blur";
          slide.prepend(clone);
        }
      });
    },
    onslide: function (index: number) {
      $(".slide.selected").removeClass("selected");
      $(".image:eq(" + index + ")")
        .parent()
        .addClass("selected");
    },
  });
}
