import * as L from "leaflet";

if (document.querySelector("#map")) {
  if (navigator.geolocation) {
    const locationField: HTMLInputElement | null =
      document.querySelector("#boat_location") ||
      document.querySelector("#marina_location");
    let latitude = 0;
    let longitude = 0;
    let currentMarker: any = null;

    const handleSuccess = (position: GeolocationPosition) => {
      latitude = position.coords.latitude;
      longitude = position.coords.longitude;

      if (locationField) locationField.value = `${latitude}, ${longitude}`;

      map.setView([latitude, longitude], 16);
    };

    const handleError = ({ code, message }: GeolocationPositionError) => {
      console.warn(`Geolocation error(${code}): ${message}`);

      map.setView([latitude, longitude], 2);
    };

    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    const onMapClick = ({ latlng }: any) => {
      if (currentMarker) {
        map.removeLayer(currentMarker);
      }

      currentMarker = L.marker(new L.LatLng(latlng.lat, latlng.lng)).addTo(map);
      if (locationField) locationField.value = `${latlng.lat}, ${latlng.lng}`;
    };

    const setLocationField = () => {
      if (currentMarker) {
        map.removeLayer(currentMarker);
      }

      if (locationField) {
        const location = locationField.value.split(", ");
        currentMarker = L.marker(
          new L.LatLng(parseInt(location[0]), parseInt(location[1])),
        ).addTo(map);
      }
    };

    const map = L.map("map");
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
      map,
    );
    map.on("click", onMapClick);

    if (locationField) {
      locationField.onchange = () => setLocationField();
      if (locationField.value) setLocationField();
    }

    navigator.geolocation.getCurrentPosition(
      handleSuccess,
      handleError,
      options,
    );
  } else {
    console.warn("Geolocation is not supported by this browser.");
  }
}
