import Plausible from "plausible-tracker";

const { trackPageview } = Plausible({
  domain: "jackyard.com",
  trackLocalhost: false,
});

trackPageview();

const { trackEvent } = Plausible({
  domain: "jackyard.com",
  trackLocalhost: false,
});

const signup = document.querySelector("#signup");
const publishAd = document.querySelector("#publish-ad");
const upgradeAd = document.querySelector("#upgrade-ad");

if (signup) {
  trackEvent("Signup");
}

if (publishAd) {
  trackEvent("Publish Ad");
}

if (upgradeAd) {
  trackEvent("Upgrade Ad");
}
