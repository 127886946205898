declare global {
  interface Window {
    jQuery: any;
    $: any;
  }
}

import jquery from "jquery";
window.jQuery = jquery;
window.$ = jquery;
